import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page" />
      </Helmet>

      <div className="heading">
        <Container>
          <h1 className="">{t(`PRODUCTS.TITLE`)}</h1>
        </Container>
      </div>

      <Container className="list-page py-5">
        {/* <ContentrArea id="product-listing_before_listing" /> */}

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products}
            filters="off"
            columnClassName="col-sm-6 col-lg-4 p-0 m-0"
            showSubtitle
            // itemClassName="random-rotation-object"
            // itemImageClassName="scribble-hover-overlay-object"
          />
        )}

        {/* <ContentrArea id="product-listing_after_listing" /> */}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
